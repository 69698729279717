//信发管理——信发屏管理列表查询
export const screenAdListQueryConditions = {
  timeSelectList: [
    {
      filterRuleFormValue: "timeQueryType",
      beginTime: "begin_time",
      endTime: "end_time",
      format: "timestamp",
    },
  ],
  filterSelect: [
    {
      name: "全部市场",
      filterRuleFormValue: "market_id",
      filterType: [],
    },
  ],
  selectList: [
    {
      name: "全部类型",
      filterRuleFormValue: "type",
      filterType: [],
    },
  ],
  filterList: [
    {
      name: "设备名称",
      filterRuleFormValue: "keyword",
      width: "320px",
    },
  ],
};

//信发管理——信发屏管理列表
export const screenAdListTableConfig = [
  { prop: "name", label: "设备名称" },
  { prop: "uuid", label: "uuid" },
  { prop: "market_name", label: "绑定市场" },
  { prop: "type_parse", label: "屏类型" },
  { prop: "created_at_parse", label: "绑定时间" },
  { prop: "connect_status_name", label: "运行状态" },
];
//信发管理——信发屏管理列表
export const screenAdStatisticsTableBtn = [
  {
    label: "解绑",
    statusList: [],
  },
];

//信发管理——核销统计列表按钮
export const codePayStatisticsTableBtn = [
  {
    label: "查看明细",
    statusList: [],
  },
];

//信发管理——素材模板列表查询
export const templateListQueryConditions = {
  timeSelectList: [
    {
      filterRuleFormValue: "timeQueryType",
      beginTime: "begin_time",
      endTime: "end_time",
      format: "timestamp",
    },
  ],
  filterList: [
    {
      name: "模板名称",
      filterRuleFormValue: "keyword",
      width: "320px",
    },
  ],
};

//信发管理——素材模板列表
export const templateListTableConfig = [
  { prop: "name", label: "模板名称" },
  { prop: "relate_name", label: "关联素材" },
  { prop: "updated_at_parse", label: "更新时间" },
  { prop: "remark", label: "备注" },
];
//信发管理—素材模板按钮
export const templateStatisticsTableBtn = [
  {
    label: "编辑",
    statusList: [],
  },
  {
    label: "查看详情",
    statusList: [],
  },
  {
    label: "下发",
    statusList: [],
  },
];

//信发管理——素材列表查询
export const materialListQueryConditions = {
  timeSelectList: [
    {
      filterRuleFormValue: "timeQueryType",
      beginTime: "begin_time",
      endTime: "end_time",
      format: "timestamp",
    },
  ],
  filterList: [
    {
      name: "素材名称",
      filterRuleFormValue: "keyword",
      width: "320px",
    },
  ],
};

//信发管理——素材列表
export const materialListTableConfig = [
  { prop: "name", label: "素材名称" },
  { prop: "resource_type_parse", label: "素材类型" },
  { prop: "updated_at_parse", label: "更新时间" },
  { prop: "remark", label: "备注" },
];

//信发管理—核销统计列表按钮
export const materialStatisticsTableBtn = [
  {
    label: "编辑",
    statusList: [],
  },
  {
    label: "查看详情",
    statusList: [],
  },
];
