<template>
  <div class="wrapper">
    <ctheader></ctheader>
    <div class="screen-ad-container">
      <el-tabs v-model="activeName">
        <el-tab-pane label="信发屏管理" name="first">
          <screenList />
        </el-tab-pane>
        <el-tab-pane label="素材模板" name="second">
          <materialTemplate />
        </el-tab-pane>
        <el-tab-pane label="素材管理" name="third">
          <materialManage />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ctheader from "../../components/ctheader";
import screenList from "./components/screenList.vue";
import materialTemplate from "./components/materialTemplate.vue";
import materialManage from "./components/materialManage.vue";

export default {
  name: "",
  components: { ctheader, screenList, materialTemplate, materialManage },
  mixins: [],
  props: {},
  data() {
    return {
      activeName: "first",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.screen-ad-container {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
}
</style>
