import request from "@/utils/request";

//素材列表
export function materiaListServe(data) {
  return request({
    url: "/api/ad/main",
    method: "post",
    data,
  });
}

//市场列表
export function marketListServe(data) {
  return request({
    url: "api/company",
    method: "post",
    data,
  });
}

//信发屏管理
export function screenAdListServe(data) {
  return request({
    url: "/api/ad/main",
    method: "post",
    data,
  });
}

//模板管理
export function templateListServe(data) {
  return request({
    url: "/api/ad/main",
    method: "post",
    data,
  });
}
