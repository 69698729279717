<template>
  <div>
    <QueryConditions
      ref="queryConditions"
      :filterList="queryConditionsObj.filterList"
      :selectList="queryConditionsObj.selectList"
      :timeSelectList="queryConditionsObj.timeSelectList"
      @query="onQueryChange"
    >
      <div slot="actions">
        <el-button size="mini" type="primary" plain @click="add" class="btn">
          新增
        </el-button>
      </div>
    </QueryConditions>
    <TableData
      ref="table"
      :tableConfig="tableConfig"
      :tableData="tableData"
      :actionList="orderBtn"
      @editMaterial="editMaterial"
      @detailMaterial="detailMaterial"
    ></TableData>
    <Pagination
      :total="total"
      :start.sync="filterRuleForm.start"
      :length.sync="filterRuleForm.length"
      @pagination="handleCurrentChange"
      ref="pagination"
    >
    </Pagination>
    <!-- 市场弹框 -->
    <el-drawer
      :title="title"
      :visible.sync="drawer"
      :direction="direction"
      size="40%"
      :before-close="close"
    >
      <div class="drawer-div">
        <el-form ref="form" :model="form" label-width="80px" size="small">
          <el-form-item label="素材名称">
            <el-input
              v-model="form.name"
              :maxlength="50"
              :disabled="title == '查看详情'"
            ></el-input>
          </el-form-item>
          <el-form-item label="素材类型">
            <el-radio-group
              v-model="form.resource_type"
              :disabled="title == '查看详情'"
            >
              <el-radio :label="1">图片</el-radio>
              <el-radio :label="2">视频</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.remark"
              :disabled="title == '查看详情'"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="上传图片" v-if="form.resource_type == 1">
            <el-upload
              action="/api/ad/main"
              list-type="picture-card"
              :data="{ action: 'huawei.upload' }"
              :on-remove="handleRemove"
              :on-success="handleAvatarSuccess"
              :file-list="fileList"
              :limit="1"
              :disabled="title == '查看详情'"
              :class="{ disUoloadSty: fileList.length != 0 }"
            >
              <span class="el-icon-plus"></span>
            </el-upload>
            <span class="span1">
              支持jpg/png,不大于2M
            </span>
          </el-form-item>
          <el-form-item label="上传视频" v-if="form.resource_type == 2">
            <el-upload
              action="/api/ad/main"
              :data="{ action: 'huawei.upload' }"
              :on-remove="handleRemove"
              :on-success="handleAvatarSuccess"
              :file-list="fileList1"
              :limit="1"
              :disabled="title == '查看详情'"
              v-if="fileList1.length == 0"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <div class="test_two_box" v-show="fileList1.length != 0">
              <el-button size="small" type="primary" @click="handleRemove">
                删除
              </el-button>
              <br />
              <video :src="form.url" class="video-avatar" controls="controls">
                您的浏览器不支持视频播放
              </video>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="pop-button" v-if="title != '查看详情'">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">
          确 定
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/new_pagination";

import QueryConditions from "@/components/queryConditions";
import TableData from "@/components/tableData";

import query from "@/mixins/query";
import {
  materialListQueryConditions,
  materialListTableConfig,
  materialStatisticsTableBtn,
} from "../config/index";

import moment from "moment";

import { materiaListServe } from "../api";

export default {
  name: "",
  components: { QueryConditions, TableData, Pagination },
  mixins: [query],
  props: {},
  data() {
    return {
      queryConditionsObj: {},
      tableConfig: [],
      tableData: [],
      orderBtn: [],
      drawer: false,
      direction: "rtl",
      form: {},
      market_data: [],
      screen_ad_type: [
        {
          id: 1,
          name: "27寸广告屏",
        },
        {
          id: 2,
          name: "43寸广告屏",
        },
      ],
      filterRuleForm: {
        action: "resource.list",
        start: 0,
        length: 10,
      },
      title: "新增",
      fileList: [],
      total: 0,
      fileList1: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.queryConditionsObj = materialListQueryConditions;
    this.tableConfig = materialListTableConfig;
    this.orderBtn = materialStatisticsTableBtn;
    // this.getMarketList();
    this.filterRequestWrapper();
  },
  methods: {
    async filterRequestWrapper() {
      const { code, data, total } = await materiaListServe(this.filterRuleForm);
      if (code == 0) {
        this.tableData = data.map((item) => {
          return {
            ...item,
            resource_type_parse: item.resource_type == 1 ? "图片" : "视频",
            updated_at_parse: moment(item.updated_at * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          };
        });
        this.total = total;
      }
    },

    add() {
      this.title = "新增";
      this.drawer = true;
      this.form = {
        action: "resource.add",
        resource_type: 1,
      };
    },
    editMaterial(val) {
      this.title = "编辑";
      this.drawer = true;
      this.form = val;
      val.resource_type == 1
        ? (this.fileList = [{ name: "", url: val.url }])
        : (this.fileList1 = [{ name: "", url: val.url }]);

      this.form.action = "resource.edit";
    },
    detailMaterial(val) {
      this.title = "查看详情";
      this.drawer = true;
      this.form = val;
      this.fileList = [{ name: "", url: val.url }];
    },
    handleAvatarSuccess(res) {
      console.log(res);
      if (res.code == 0) {
        this.form.url = res.data.url;
        this.form.resource_type == 1
          ? (this.fileList = [{ name: "", url: res.data.url }])
          : (this.fileList1 = [{ name: "", url: res.data.url }]);
      } else {
        this.$message.warning(res.msg);
      }
    },
    async save() {
      const { code, msg } = await materiaListServe(this.form);
      if (code == 0) {
        this.$message.success(msg);
        this.drawer = false;
        this.form = {};
        this.fileList = [];
        this.fileList1 = [];

        this.filterRequestWrapper();
      }
    },
    close() {
      this.drawer = false;
      this.form = {};
      this.fileList = [];
      this.fileList1 = [];
    },
    handleRemove() {
      this.fileList = [];
      this.fileList1 = [];
      this.form.url = "";
    },

    // //素材列表
    // getMaterialList() {
    //   this.filterRuleForm.action = "resource.list";
    //   this.filterRuleForm.start = 0;
    //   let $this = this;
    //   apiPost({
    //     url: APIs.SCREEN,
    //     data: this.filterRuleForm,
    //     // data: Object.assign({ action: "market.list" }, $this.search),
    //     success(res) {
    //       if (res.code === "0") {
    //         $this.market_data = res.data;
    //       } else {
    //         $this.data = [];
    //         // $this.config.total = 0;
    //         $this.total = {};
    //       }
    //     },
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
.drawer-div {
  padding: 24px;
}
</style>
<style lang="less">
.drawer-div {
  padding: 24px;
  .el-input,
  .el-select {
    width: 500px;
  }
}
.pop-button {
  // margin: 200px auto 0;
  bottom: 20px;
  position: absolute;
  width: 100%;
  display: flex;
  padding: 0 12px;
  .el-button {
    width: 100%;
  }
}

.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
.video-avatar {
  width: 400px;
  height: 300px;
}
</style>
