<template>
  <div>
    <QueryConditions
      ref="queryConditions"
      :filterList="queryConditionsObj.filterList"
      :selectList="queryConditionsObj.selectList"
      :timeSelectList="queryConditionsObj.timeSelectList"
      @query="onQueryChange"
    >
      <div slot="actions">
        <el-button size="mini" type="primary" plain @click="add" class="btn">
          新增
        </el-button>
      </div>
    </QueryConditions>
    <TableData
      ref="table"
      :tableConfig="tableConfig"
      :tableData="tableData"
      :actionList="orderBtn"
      @editTemplate="editTemplate"
      @send="send"
      @detailTemplate="detailTemplate"
    ></TableData>
    <Pagination
      :total="total"
      :start.sync="filterRuleForm.start"
      :length.sync="filterRuleForm.length"
      @pagination="handleCurrentChange"
      ref="pagination"
    >
    </Pagination>
    <!-- 市场弹框 -->
    <el-drawer
      :title="title"
      :visible.sync="drawer"
      :direction="direction"
      size="50%"
      :before-close="handleClose"
    >
      <div class="drawer-div">
        <el-form ref="form" :model="form" label-width="80px" size="small">
          <el-form-item label="模板名称">
            <el-input
              v-model="form.name"
              :maxlength="50"
              :disabled="title == '查看详情'"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="2"
              :disabled="title == '查看详情'"
            ></el-input>
          </el-form-item>
          <el-form-item label="添加素材">
            <el-button
              type="text"
              @click="innerDrawerClick"
              :disabled="title == '查看详情'"
            >
              选择素材
            </el-button>
          </el-form-item>

          <el-table
            :data="form.resource"
            style="width: 100%;height: calc(100vh - 400px);overflow-y: scroll;"
          >
            <el-table-column prop="name" label="素材名称" width="180">
            </el-table-column>
            <el-table-column label="素材类型">
              <template slot-scope="scope">
                {{ scope.row.resource_type == 1 ? "图片" : "视频" }}
              </template>
            </el-table-column>
            <el-table-column label="权重" width="180">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.rank"
                  :min="1"
                  size="small"
                  :controls="false"
                  class="inner"
                  :disabled="title == '查看详情'"
                  :precision="0"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="播放时间（s)" width="180">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.rotation_time"
                  :min="1"
                  size="small"
                  :controls="false"
                  class="inner"
                  :disabled="
                    title == '查看详情' || scope.row.resource_type == 2
                  "
                  :precision="0"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="del(scope.row)"
                  :disabled="title == '查看详情'"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <div class="pop-button" v-if="title != '查看详情'">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveTemplate">
          确 定
        </el-button>
      </div>
      <el-drawer
        title="素材列表"
        :append-to-body="true"
        :visible.sync="innerDrawer"
        size="40%"
      >
        <div class="gird-data">
          <el-form
            :inline="true"
            :model="gridForm"
            class="demo-form-inline"
            size="small"
          >
            <el-form-item label="素材名称">
              <el-input
                v-model="gridForm.keyword"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="queryMaterial">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="saveMaterial" plain>
                保存
              </el-button>
            </el-form-item>
          </el-form>

          <el-table
            :data="girdData"
            :row-key="getRowKeys"
            @select="userHandleChange"
            @select-all="selcectAllChange"
            style="width: 100%;margin-top:20px"
            :header-cell-style="getRowClass"
            ref="multipleApiTable"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column prop="name" label="素材名称"> </el-table-column>
            <el-table-column prop="resource_type_parse" label="素材类型">
            </el-table-column>
            <el-table-column prop="updated_at_parse" label="更新时间">
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
          </el-table>

          <Pagination
            :total="material_total"
            :start.sync="gridForm.start"
            :length.sync="gridForm.length"
            @pagination="handleCurrentChange1"
            ref="pagination1"
          >
          </Pagination>
        </div>
      </el-drawer>
    </el-drawer>
    <el-drawer
      title="下发设备"
      :visible.sync="send_drawer"
      :direction="direction"
      size="40%"
    >
      <div class="drawer-div">
        <el-form
          ref="sendForm"
          :model="sendForm"
          label-width="100px"
          size="small"
        >
          <el-form-item label="选择下发设备">
            <el-select
              v-model="sendForm.screen_ids"
              filterable
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in sendData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="pop-button">
        <el-button @click="send_drawer = false">取 消</el-button>
        <el-button type="primary" @click="saveSend">
          确 定
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
// import pagination from "@/components/pagination";
import Pagination from "@/components/new_pagination";
import QueryConditions from "@/components/queryConditions";
import TableData from "@/components/tableData";

import query from "@/mixins/query";
import {
  templateListQueryConditions,
  templateListTableConfig,
  templateStatisticsTableBtn,
} from "../config/index";
import { templateListServe, materiaListServe, screenAdListServe } from "../api";
import moment from "moment";

export default {
  name: "",
  components: { Pagination, QueryConditions, TableData },
  mixins: [query],
  props: {},
  data() {
    return {
      queryConditionsObj: {},
      tableConfig: [],
      tableData: [],
      orderBtn: [],
      drawer: false,
      direction: "rtl",
      form: {},
      filterRuleForm: {
        action: "resource.template.list",
        start: 0,
        length: 10,
      },
      title: "新增",
      innerDrawer: false,
      girdData: [],
      gridForm: {
        action: "resource.list",
        start: 0,
        length: 10,
      },
      material_total: 0,
      changeId: [],
      selectAdd: [],
      send_drawer: false,
      sendForm: {},
      sendData: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.queryConditionsObj = templateListQueryConditions;
    this.tableConfig = templateListTableConfig;
    this.orderBtn = templateStatisticsTableBtn;
    this.filterRequestWrapper();
  },
  methods: {
    getRowClass({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    //模板列表
    async filterRequestWrapper() {
      const { code, data, total } = await templateListServe(
        this.filterRuleForm
      );
      if (code == 0) {
        this.tableData = data.map((item) => {
          return {
            ...item,
            resource_type_parse: item.resource_type == 1 ? "图片" : "视频",
            updated_at_parse: moment(item.updated_at * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          };
        });
        this.total = total;
      }
    },
    //素材列表
    async getMaterialList() {
      const { code, data, total } = await materiaListServe(this.gridForm);
      if (code == 0) {
        if (this.form.resource && this.form.resource.length != 0) {
          console.log(this.form.resource);

          this.girdData = data.map((item) => {
            return {
              ...item,
              resource_type_parse: item.resource_type == 1 ? "图片" : "视频",
              updated_at_parse: moment(item.updated_at * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              rank:
                this.form.resource.filter((c) => c.resource_id == item.id)
                  .length > 0
                  ? this.form.resource.filter(
                      (c) => c.resource_id == item.id
                    )[0].rank
                  : undefined,
              rotation_time:
                this.form.resource.filter((c) => c.resource_id == item.id)
                  .length > 0
                  ? this.form.resource.filter(
                      (c) => c.resource_id == item.id
                    )[0].rotation_time
                  : undefined,
            };
          });
        } else {
          this.girdData = data.map((item) => {
            return {
              ...item,
              resource_type_parse: item.resource_type == 1 ? "图片" : "视频",
              updated_at_parse: moment(item.updated_at * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            };
          });
        }
        console.log(this.girdData);

        this.material_total = total;
        if (this.form.resource && this.form.resource.length != 0) {
          this.selectAdd = this.form.resource.map((item) => item.resource_id); //拿到当页匹配的设备id
          console.log("已有设备id数组", this.selectAdd);
          // this.form.resource.map((item, index) => {
          // this.form.resource.forEach((item2) => {
          //   if (item2.resource_id == item.id) {
          //     item = {
          //       ...item2,
          //       id: item2.resource_id,
          //     };
          //   }
          // });

          // });
          console.log(this.girdData);
          //回显选中数据
          this.$nextTick(() => {
            this.girdData.forEach((item, index) => {
              this.selectAdd.forEach((item1) => {
                if (item.id == item1) {
                  this.$refs.multipleApiTable.toggleRowSelection(
                    this.girdData[index],
                    true
                  );
                }
              });
            });
          });
        }
      }
    },
    //保存模板
    async saveTemplate() {
      const { code, msg } = await templateListServe(this.form);
      if (code == 0) {
        this.$message.success(msg);
        this.drawer = false;
        this.filterRequestWrapper();
        this.$refs.multipleApiTable.clearSelection();
      }
    },
    queryMaterial() {
      this.gridForm.start = 0;
      this.getMaterialList();
    },
    saveMaterial() {
      console.log("当前页设备id", this.selectAdd, "原数组id", this.changeId);

      this.innerDrawer = false;
    },
    add() {
      this.title = "新增";
      this.drawer = true;
      this.form.action = "resource.template.add";
    },
    editTemplate(val) {
      this.title = "编辑";
      this.form = {
        ...val,
        action: "resource.template.edit",
      };
      this.drawer = true;
    },
    detailTemplate(val) {
      this.title = "查看详情";
      this.form = val;
      this.drawer = true;
    },
    getRowKeys(row) {
      return row.id;
    },
    handleClose() {
      this.form = {};
      this.drawer = false;
      this.$refs.multipleApiTable.clearSelection();
    },
    innerDrawerClick() {
      this.getMaterialList();
      this.innerDrawer = true;
    },
    handleCurrentChange1() {
      console.log("1212");
      console.log(this.$refs.pagination1);
      this.gridForm.start =
        this.gridForm.length * (this.$refs.pagination1.currentPage - 1);
      this.getMaterialList();
    },
    // 删除其中一条
    del(index) {
      this.form.resource.some((item, i) => {
        if (item.resource_id == index.resource_id) {
          this.form.resource.splice(i, 1); //在数组的some方法中，如果return true，就会立即终止这个数组的后续循环
          return true;
        }
      });

      // this.form.resource.splice(index, 1);
    },
    async send(val) {
      this.sendForm.id = val;
      this.getScreenAdList();
      const { code, data } = await templateListServe({
        action: "resource.template.send.get",
        id: val,
      });
      if (code == 0) {
        this.send_drawer = true;
        this.sendForm = {
          ...this.sendForm,
          screen_ids: data,
        };
      }
    },
    //获取信发屏列表
    async getScreenAdList() {
      const { code, data } = await screenAdListServe({
        action: "screen.list",
        start: 0,
        length: 99999,
      });
      if (code == 0) {
        this.sendData = data;
      }
    },
    //下发保存
    async saveSend() {
      this.sendForm.action = "resource.template.send";
      const { code, msg } = await templateListServe(this.sendForm);
      if (code == 0) {
        this.$message.success(msg);
        this.send_drawer = false;
        this.filterRequestWrapper();
      }
    },
    //当用户手动勾选数据行的 Checkbox 时触发的事件
    userHandleChange(val, row) {
      console.log("进1", val, row);
      //如果手动勾选所有设备按钮为选中状态,则初始化按钮,并显示分页
      // 拿到当前页选中的id数组
      if (val != null && val != "") {
        var numbers = "";
        for (let obj of val) {
          // console.log("选中", obj.id);
          if (numbers == "") {
            numbers += obj.id;
          } else {
            numbers += "," + obj.id;
          }
        }
        this.newArr = numbers;
        this.downArr = numbers.split(",");
        //目前选中的数组id
        let changeType = this.downArr.map(Number);
        //要添加的过滤新数据数组
        const _arr1 = changeType.filter(
          (item1) => !this.selectAdd.includes(item1)
        );
        if (_arr1.length > 0) {
          //有新元素
          // this.changeId后台返的回显id数组
          //将新数组添加进原数组id
          this.changeId = Array.from(new Set([...this.changeId, ..._arr1]));
          this.selectAdd = Array.from(new Set([...this.selectAdd, ..._arr1]));
          // console.log('过滤',_arr1,'添加后的原数组id', this.changeId )
        } else {
          //删除元素操作
          //要删除的数据数组
          const _arr2 = this.selectAdd.filter(
            (item2) => !changeType.includes(item2)
          );
          //将要删除的数组从原数组中删掉
          this.changeId.splice(
            this.changeId.findIndex((item) => item == _arr2),
            1
          );
          this.selectAdd.splice(
            this.selectAdd.findIndex((item) => item == _arr2),
            1
          );
        }
        console.log(
          "当前选中",
          changeType,
          "当前页设备id",
          this.selectAdd,
          "原数组id",
          this.changeId
        );
        this.form.resource = val.map((item) => {
          return {
            name: item.name,
            resource_id: item.id,
            resource_type: item.resource_type,
            rank: item.rank,
            rotation_time: item.rotation_time,
            resource_url: item.url,
          };
        });
        // const arr = val.map((item) => {
        //   return {
        //     name: item.name,
        //     resource_id: item.id,
        //     resource_type: item.resource_type,
        //     isData: 1,
        //   };
        // });
        // console.log(this.form.resource);
        // const arr1 = this.form.resource;
        // console.log(arr.concat(arr1));
        // if (arr1 && arr1.length > 0) {
        //   this.form.resource = arr
        //     .concat(arr1)
        //     .filter((c) => !c.rank && c.isData)
        //     // (c.rank && !c.isData) ||
        //     //     (c.rotation_time && !c.isData) ||
        //     //     c.isData
        //     .map((item) => {
        //       return {
        //         name: item.name,
        //         resource_id: item.resource_id,
        //         resource_type: item.resource_type,
        //         rank: item.rank,
        //         rotation_time: item.rotation_time,
        //       };
        //     });
        //   console.log(this.form.resource);
        // } else {
        //   this.form.resource = val.map((item) => {
        //     return {
        //       name: item.name,
        //       resource_id: item.id,
        //       resource_type: item.resource_type,
        //     };
        //   });
        // }
      } else {
        this.newArr = "";
        this.downArr = [];
      }
    },
    //全选/取消
    selcectAllChange(val) {
      // console.log('全部',val);
      let arr = [];
      //拿到当前页全选的数组id
      val.forEach((item) => {
        arr.push(item.id);
      });
      let arr1 = arr.filter((item1) => !this.selectAdd.includes(item1));
      if (arr1.length > 0) {
        //有新元素,往原数组和当前分页选中数组添加
        //原数组数据
        this.changeId = Array.from(new Set([...this.changeId, ...arr1]));
        //当前分页数组
        this.selectAdd = Array.from(new Set([...this.selectAdd, ...arr1]));
      } else {
        //删除所有元素操作
        let arr2 = this.selectAdd.filter((item2) => !arr.includes(item2));
        //删除原数组中当前页全选中的所有id
        this.changeId = this.changeId.filter((item) => {
          return !arr2.includes(item);
        });
        //删除当前选中数组中全选中的所有id
        this.selectAdd = this.selectAdd.filter((item) => {
          return !arr2.includes(item);
        });
      }
      console.log(
        "当前选中",
        arr,
        "当前页设备id",
        this.selectAdd,
        "原数组id",
        this.changeId,
        "新元素",
        arr1
      );
      this.form.resource = val.map((item) => {
        return {
          name: item.name,
          resource_id: item.id,
          resource_type: item.resource_type,
          resource_url: item.url,
        };
      });
    },
  },
};
</script>
<style lang="less" scoped>
.drawer-div {
  padding: 24px;
}
</style>
<style lang="less">
.drawer-div {
  padding: 24px;
  .el-input,
  .el-select,
  .el-textarea__inner {
    width: 500px;
  }
}
.pop-button {
  // margin: 200px auto 0;
  bottom: 20px;
  position: absolute;
  width: 100%;
  display: flex;
  padding: 0 12px;
  .el-button {
    width: 100%;
  }
}
.gird-data {
  padding: 24px;
}
.inner {
  .el-input {
    width: 100px !important;
  }
}
</style>
