<template>
  <div>
    <QueryConditions
      ref="queryConditions"
      :filterList="queryConditionsObj.filterList"
      :selectList="queryConditionsObj.selectList"
      :timeSelectList="queryConditionsObj.timeSelectList"
      @query="onQueryChange"
    >
      <div slot="actions">
        <el-button size="mini" type="primary" plain @click="add" class="btn">
          新增
        </el-button>
      </div>
    </QueryConditions>
    <TableData
      ref="table"
      :tableConfig="tableConfig"
      :tableData="tableData"
      :actionList="orderBtn"
      @unbind="unbind"
    ></TableData>
    <Pagination
      :total="total"
      :start.sync="filterRuleForm.start"
      :length.sync="filterRuleForm.length"
      @pagination="handleCurrentChange"
      ref="pagination"
    >
    </Pagination>

    <el-drawer
      :title="title"
      :visible.sync="drawer"
      :direction="direction"
      size="40%"
      :before-close="close"
    >
      <div class="drawer-div">
        <el-form ref="form" :model="form" label-width="80px" size="small">
          <el-form-item label="设备名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="uuid">
            <el-input v-model="form.uuid"></el-input>
          </el-form-item>
          <el-form-item label="屏类型">
            <el-select v-model="form.type" placeholder="请选择">
              <el-option
                v-for="item in screen_ad_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定市场">
            <el-select v-model="form.market_id" filterable placeholder="请选择">
              <el-option
                v-for="item in market_data"
                :key="item.id"
                :label="item.market_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="pop-button">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">
          确 定
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
// import pagination from "@/components/pagination";

import QueryConditions from "@/components/queryConditions";
import TableData from "@/components/tableData";
import Pagination from "@/components/new_pagination";

import query from "@/mixins/query";
import {
  screenAdListQueryConditions,
  screenAdListTableConfig,
  screenAdStatisticsTableBtn,
} from "../config/index";
import { marketListServe, screenAdListServe } from "../api";
import moment from "moment";

export default {
  name: "",
  components: { QueryConditions, TableData, Pagination },
  mixins: [query],
  props: {},
  data() {
    return {
      queryConditionsObj: {},
      tableConfig: [],
      tableData: [],
      orderBtn: [],
      drawer: false,
      direction: "rtl",
      form: {},
      market_data: [],
      screen_ad_type: [
        {
          id: 1,
          name: "27寸广告屏",
        },
        {
          id: 2,
          name: "43寸广告屏",
        },
      ],
      gridForm: {
        action: "market.list",
        start: 0,
        length: 99999,
      },
      filterRuleForm: {
        action: "screen.list",
        start: 0,
        length: 10,
      },
      title: "新增",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.queryConditionsObj = screenAdListQueryConditions;
    this.tableConfig = screenAdListTableConfig;
    this.orderBtn = screenAdStatisticsTableBtn;
    this.queryConditionsObj.selectList[0].filterType = [
      {
        label: "27寸广告屏",
        value: 1,
      },
      {
        label: "43寸广告屏",
        value: 2,
      },
    ];
    this.getMarketList();
    this.filterRequestWrapper();
  },
  methods: {
    add() {
      this.title = "新增";
      this.drawer = true;
      this.form.action = "screen.add";
    },
    async filterRequestWrapper() {
      const { code, data, total } = await screenAdListServe(
        this.filterRuleForm
      );
      if (code == 0) {
        this.tableData = data.map((item) => {
          return {
            ...item,
            type_parse: item.type == 1 ? "27寸广告屏" : "43寸广告屏",
            created_at_parse: moment(item.created_at * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          };
        });
        this.total = total;
      }
    },
    async save() {
      const { code, msg } = await screenAdListServe(this.form);
      if (code == 0) {
        this.$message.success(msg);
        this.drawer = false;
        this.filterRequestWrapper();
        this.form = {};
      }
    },
    close() {
      this.drawer = false;
      this.form = {};
    },
    async unbind(val) {
      const { code, msg } = await screenAdListServe({
        action: "screen.edit",
        id: val,
      });
      if (code == 0) {
        this.$message.success(msg);
        this.filterRequestWrapper();
      }
    },
    async getMarketList() {
      const { code, data } = await marketListServe({
        action: "market.list",
        start: 0,
        length: 99999,
      });
      if (code == 0) {
        this.market_data = data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.drawer-div {
  padding: 24px;
}
</style>
<style lang="less">
.drawer-div {
  padding: 24px;
  .el-input,
  .el-select {
    width: 500px;
  }
}
.pop-button {
  // margin: 200px auto 0;
  bottom: 20px;
  position: absolute;
  width: 100%;
  display: flex;
  padding: 0 12px;
  .el-button {
    width: 100%;
  }
}
</style>
